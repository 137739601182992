import { cn } from '@hapstack/common'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'

import type { IconName, IconStyleProps } from './Icon'
import { Icon } from './Icon'
import { Spinner } from './Spinner'

export const inputStyles = cva(
  'flex w-full rounded-md border border-input focus:border-primary/40 bg-white file:border-0 file:bg-transparent file:text-md file:font-medium file:cursor-pointer placeholder:text-muted-foreground/70 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 px-3 ring-0 focus:ring-0 transition-colors duration-300 hover:border-primary/20',
  {
    variants: {
      size: {
        xs: 'text-sm h-10',
        sm: 'text-md h-11',
        md: 'text-md h-12',
        lg: 'text-base h-14',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
)

export type InputStyleProps = VariantProps<typeof inputStyles>
export type InputProps = InputStyleProps &
  Omit<React.ComponentPropsWithoutRef<'input'>, 'size'> & {
    loading?: boolean
    icon?: IconName
  }

const iconSizeMap: Record<
  NonNullable<InputStyleProps['size']>,
  IconStyleProps['size']
> = {
  xs: 'sm',
  sm: 'sm',
  md: 'md',
  lg: 'md',
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ size, className, icon, loading, ...props }, forwardedRef) => {
    return (
      <div className="relative w-full">
        <input
          type="text"
          ref={forwardedRef}
          {...props}
          className={cn(inputStyles({ size }), icon && 'peer pl-10', className)}
        />
        {icon ? (
          <div className="absolute left-0 top-0 flex h-full w-10 items-center justify-center text-muted-foreground peer-focus:text-accent">
            <Icon
              name={icon}
              size={iconSizeMap[size || 'md']}
            />
          </div>
        ) : null}
        {loading ? (
          <div className="absolute right-0 top-0 flex h-full w-10 items-center justify-center">
            <Spinner size={iconSizeMap[size || 'sm']} />
          </div>
        ) : null}
      </div>
    )
  }
)

Input.displayName = 'Input'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          'flex min-h-16 w-full rounded-md border border-input bg-white px-3 py-3 text-md placeholder:text-muted-foreground/70 focus:border-primary/40 focus:ring-0 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Textarea.displayName = 'Textarea'

export { Textarea }
