import { cn } from '@hapstack/common'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'

import type { IconName } from './Icon'
import { Icon } from './Icon'

export const iconButtonStyles = cva(
  'inline-flex justify-center items-center transition-all duration-300 rounded-full disabled:pointer-events-none disabled:opacity-30',
  {
    variants: {
      size: {
        xs: 'size-7 p-1.5',
        sm: 'size-8 p-2',
        md: 'size-10 p-3',
        lg: 'size-12 p-3',
      },
      variant: {
        default:
          'text-primary hover:text-primary/80 hover:bg-muted border border-border',
        secondary: 'bg-gray-200 hover:bg-gray-300 p-2 text-gray-500',
        light: 'text-muted-foreground hover:text-muted-foreground/80',
        ghost: '',
      },
    },
    defaultVariants: {
      size: 'sm',
      variant: 'default',
    },
  }
)

type DefaultButtonProps = React.ComponentPropsWithRef<'button'>
export type IconButtonStyleProps = VariantProps<typeof iconButtonStyles>
type IconButtonProps = IconButtonStyleProps &
  DefaultButtonProps & { icon: IconName; label: string; loading?: boolean }

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    { size, loading, disabled, label, icon, variant, ...props },
    forwardedRef
  ) => {
    return (
      <button
        ref={forwardedRef}
        disabled={disabled || loading}
        aria-label={label}
        {...props}
        className={cn(iconButtonStyles({ size, variant }), props.className)}
      >
        <Icon
          name={loading ? 'loader-2' : icon}
          className={cn('h-full w-full shrink-0', loading && 'animate-spin')}
        />
      </button>
    )
  }
)

IconButton.displayName = 'IconButton'
