import { cn } from '@hapstack/common'
import * as SliderPrimitive from '@radix-ui/react-slider'
import { forwardRef } from 'react'

const Slider = forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex w-full touch-none select-none items-center',
      className
    )}
    {...props}
  />
))
Slider.displayName = SliderPrimitive.Root.displayName

const SliderTrack = forwardRef<
  React.ElementRef<typeof SliderPrimitive.Track>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Track>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Track
    ref={ref}
    className={cn(
      'relative h-1.5 w-full grow overflow-hidden rounded-full bg-primary/20',
      className
    )}
    {...props}
  />
))

SliderTrack.displayName = SliderPrimitive.Track.displayName

const SliderRange = forwardRef<
  React.ElementRef<typeof SliderPrimitive.Range>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Range>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Range
    ref={ref}
    className={cn('absolute h-full bg-primary', className)}
    {...props}
  />
))

SliderRange.displayName = SliderPrimitive.Range.displayName

const SliderThumb = forwardRef<
  React.ElementRef<typeof SliderPrimitive.Thumb>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Thumb>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Thumb
    ref={ref}
    className={cn(
      'block h-4 w-4 rounded-full border border-primary/50 bg-background shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
      className
    )}
    {...props}
  />
))

SliderThumb.displayName = SliderPrimitive.Thumb.displayName

export { Slider, SliderRange, SliderThumb, SliderTrack }
