import { cn } from '@hapstack/common'
import * as CollapsiblePrimitive from '@radix-ui/react-collapsible'
import { forwardRef } from 'react'

const Collapsible = CollapsiblePrimitive.Root

const CollapsibleTrigger = CollapsiblePrimitive.CollapsibleTrigger

const CollapsibleContent = forwardRef<
  React.ElementRef<typeof CollapsiblePrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof CollapsiblePrimitive.Content>
>(({ children, className, ...props }, ref) => {
  return (
    <CollapsiblePrimitive.Content
      {...props}
      className="overflow-hidden data-[state=closed]:animate-collapse-up data-[state=open]:animate-collapse-down"
      ref={ref}
    >
      <div className={cn(className)}>{children}</div>
    </CollapsiblePrimitive.Content>
  )
})

CollapsibleContent.displayName = CollapsiblePrimitive.Content.displayName

export { Collapsible, CollapsibleContent, CollapsibleTrigger }
