import {
  AnimatePresence,
  type AnimatePresenceProps,
  motion,
} from 'framer-motion'
import type { ComponentProps } from 'react'
import { forwardRef } from 'react'

const AnimatedList = ({
  children,
  presenceAffectsLayout = false,
  ...props
}: { children: React.ReactNode } & AnimatePresenceProps) => {
  return (
    <AnimatePresence
      {...props}
      presenceAffectsLayout={presenceAffectsLayout}
      initial={props.initial || false}
    >
      {children}
    </AnimatePresence>
  )
}

const AnimatedListItem = forwardRef<
  React.ElementRef<'div'>,
  ComponentProps<typeof motion.div>
>(({ ...props }, ref) => {
  return (
    <motion.div
      {...props}
      ref={ref}
      layout
      initial="exit"
      animate="enter"
      exit="exit"
      transition={{
        opacity: { duration: 0.2 },
        height: { duration: 0.3 },
      }}
      variants={{
        enter: { opacity: 1, height: 'auto' },
        exit: { opacity: 0, height: 0 },
      }}
    >
      {props.children}
    </motion.div>
  )
})

AnimatedListItem.displayName = 'AnimatedListItem'

AnimatedList.Item = AnimatedListItem

export { AnimatedList }
