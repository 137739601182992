import { cn } from '@hapstack/common'
import type { ComponentProps, ElementRef, FC } from 'react'
import { forwardRef } from 'react'
import * as CurrencyInputExports from 'react-currency-input-field'

import type { IconName } from './Icon'
import { Icon } from './Icon'
import { type InputStyleProps, inputStyles } from './Inputs'

// Workaround for reported issue with react-currency-input-field
// https://github.com/cchanxzy/react-currency-input-field/issues/335

const CurrencyInputPrimitive =
  'default' in CurrencyInputExports.default
    ? ((CurrencyInputExports.default as any)
        .default as FC<CurrencyInputExports.CurrencyInputProps>)
    : CurrencyInputExports.default

type CurrencyInputProps = Omit<
  ComponentProps<typeof CurrencyInputPrimitive>,
  'size'
> &
  InputStyleProps & {
    icon?: IconName
  }

const CurrencyInput = forwardRef<ElementRef<'input'>, CurrencyInputProps>(
  (
    {
      size,
      className,
      allowNegativeValue = false,
      decimalsLimit = 2,
      decimalScale = 2,
      prefix = '$',
      icon,
      ...props
    },
    forwardedRef
  ) => {
    return (
      <div className="relative">
        <CurrencyInputPrimitive
          ref={forwardedRef}
          className={cn(inputStyles({ size }), icon && 'peer pl-10', className)}
          prefix={prefix}
          decimalsLimit={decimalsLimit}
          decimalScale={decimalScale}
          allowNegativeValue={allowNegativeValue}
          {...props}
        />
        {icon ? (
          <div className="absolute left-0 top-0 flex h-full w-10 items-center justify-center text-muted-foreground peer-focus:text-accent">
            <Icon
              name={icon}
              size="md"
            />
          </div>
        ) : null}
      </div>
    )
  }
)

CurrencyInput.displayName = 'CurrencyInput'

export { CurrencyInput }
