import { cn } from '@hapstack/common'
import * as AlertPrimitive from '@radix-ui/react-alert-dialog'
import * as React from 'react'

import { buttonStyles } from './Button'
import { H2 } from './Headings'

const Alert = ({ children, ...props }: AlertPrimitive.AlertDialogProps) => (
  <AlertPrimitive.Root {...props}>{children}</AlertPrimitive.Root>
)

const AlertTrigger = AlertPrimitive.Trigger

const AlertPortal = ({
  children,
  ...props
}: AlertPrimitive.AlertDialogPortalProps) => (
  <AlertPrimitive.Portal {...props}>
    <div className="fixed inset-0 z-50 flex items-end justify-center sm:items-center">
      {children}
    </div>
  </AlertPrimitive.Portal>
)
AlertPortal.displayName = AlertPrimitive.Portal.displayName

const AlertOverlay = React.forwardRef<
  React.ElementRef<typeof AlertPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof AlertPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <AlertPrimitive.Overlay
    className={cn(
      'fixed inset-0 z-50 bg-white/80 backdrop-blur-sm transition-opacity animate-in fade-in',
      className
    )}
    {...props}
    ref={ref}
  />
))

AlertOverlay.displayName = AlertPrimitive.Overlay.displayName

const AlertContent = React.forwardRef<
  React.ElementRef<typeof AlertPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AlertPrimitive.Content>
>(({ className, ...props }, ref) => {
  return (
    <AlertPortal>
      <AlertOverlay />
      <AlertPrimitive.Content
        ref={ref}
        className={cn(
          'fixed z-50 grid w-full max-w-lg scale-100 gap-4 border bg-white p-6 opacity-100 shadow-lg animate-in fade-in-90 slide-in-from-bottom-10 sm:rounded-lg sm:zoom-in-90 sm:slide-in-from-bottom-0 md:w-full',
          className
        )}
        {...props}
      />
    </AlertPortal>
  )
})

AlertContent.displayName = AlertPrimitive.Content.displayName

const AlertHeader = ({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col space-y-2 text-center sm:text-left',
      className
    )}
    {...props}
  >
    {children || (
      <>
        <AlertTitle />
        <AlertDescription />
      </>
    )}
  </div>
)
AlertHeader.displayName = 'AlertHeader'

const AlertFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col-reverse items-center sm:flex-row sm:justify-end sm:space-x-2',
      className
    )}
    {...props}
  />
)
AlertFooter.displayName = 'AlertFooter'

const AlertTitle = React.forwardRef<
  React.ElementRef<typeof AlertPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof AlertPrimitive.Title>
>(({ className, children, ...props }, ref) => (
  <AlertPrimitive.Title
    ref={ref}
    asChild
    {...props}
  >
    <H2 className={className}>{children || 'Are you sure?'}</H2>
  </AlertPrimitive.Title>
))
AlertTitle.displayName = AlertPrimitive.Title.displayName

const AlertDescription = React.forwardRef<
  React.ElementRef<typeof AlertPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof AlertPrimitive.Description>
>(({ className, children, ...props }, ref) => (
  <AlertPrimitive.Description
    ref={ref}
    className={cn(className)}
    {...props}
  >
    {children || 'This action cannot be undone.'}
  </AlertPrimitive.Description>
))
AlertDescription.displayName = AlertPrimitive.Description.displayName

const AlertAction = React.forwardRef<
  React.ElementRef<typeof AlertPrimitive.Action>,
  React.ComponentPropsWithoutRef<typeof AlertPrimitive.Action>
>(({ className, children, ...props }, ref) => (
  <AlertPrimitive.Action
    ref={ref}
    className={cn(buttonStyles({ variant: 'destructive' }), className)}
    {...props}
  >
    {children}
  </AlertPrimitive.Action>
))
AlertAction.displayName = AlertPrimitive.Action.displayName

const AlertCancel = React.forwardRef<
  React.ElementRef<typeof AlertPrimitive.Cancel>,
  React.ComponentPropsWithoutRef<typeof AlertPrimitive.Cancel>
>(({ className, children, ...props }, ref) => (
  <AlertPrimitive.Cancel
    ref={ref}
    className={cn(buttonStyles({ variant: 'link' }), className)}
    {...props}
  >
    {children || 'Cancel'}
  </AlertPrimitive.Cancel>
))

AlertCancel.displayName = AlertPrimitive.Cancel.displayName

Alert.Trigger = AlertTrigger
Alert.Content = AlertContent
Alert.Header = AlertHeader
Alert.Title = AlertTitle
Alert.Description = AlertDescription
Alert.Footer = AlertFooter
Alert.Cancel = AlertCancel
Alert.Action = AlertAction

export { Alert }
