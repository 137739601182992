import { ParentSize } from '@visx/responsive'
import type { XYChart as XYChartPrimitive } from '@visx/xychart'
import { buildChartTheme, DataProvider } from '@visx/xychart'
import type { ComponentProps } from 'react'

const brandTheme = buildChartTheme({
  backgroundColor: '#353A49',
  colors: ['#FF31C5', '#353A49'],
  gridColor: '#e7e5e4',
  gridColorDark: '#353A49',
  tickLength: 0,
  htmlLabel: {
    color: 'white',
    padding: '8px 8px',
  },
  xAxisLineStyles: {
    strokeWidth: 1,
  },
  yAxisLineStyles: {
    strokeWidth: 1,
  },
})

type ChartProps = Required<
  Pick<
    ComponentProps<typeof XYChartPrimitive>,
    'xScale' | 'yScale' | 'children'
  >
> & {
  theme?: Pick<ComponentProps<typeof XYChartPrimitive>, 'theme'>
}

export const ResponsiveChart = ({
  xScale,
  yScale,
  theme,
  children,
}: ChartProps) => {
  return (
    <ParentSize>
      {({ width, height }) => {
        if (width === 0 || height === 0) return null
        return (
          <DataProvider
            xScale={xScale}
            yScale={yScale}
            initialDimensions={{ width, height }}
            theme={{ ...brandTheme, ...theme }}
          >
            {children}
          </DataProvider>
        )
      }}
    </ParentSize>
  )
}
