import { cn } from '@hapstack/common'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'

import { Icon } from './Icon'

const checkboxStyles = cva(
  'peer bg-white shrink-0 rounded-sm border border-border ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-accent data-[state=checked]:text-primary-foreground data-[state=checked]:border-accent flex items-center justify-center',
  {
    variants: {
      size: {
        xs: 'size-4',
        sm: 'size-5',
        md: 'size-6',
        lg: 'size-7',
      },
    },
    defaultVariants: {
      size: 'sm',
    },
  }
)

type CheckboxStyles = VariantProps<typeof checkboxStyles>

export const Checkbox = forwardRef(
  (
    {
      className,
      checked,
      size,
      ...props
    }: CheckboxPrimitive.CheckboxProps & CheckboxStyles,
    forwardedRef?: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <CheckboxPrimitive.Root
        {...props}
        checked={checked}
        ref={forwardedRef}
        className={cn(checkboxStyles({ size }), className)}
      >
        <CheckboxPrimitive.Indicator
          className={cn(
            'text-white',
            size === 'xs' && 'text-xxs',
            size === 'sm' && 'text-xs',
            size === 'md' && 'text-sm',
            size === 'lg' && 'text-base'
          )}
        >
          <div className="mt-px font-extrabold">
            {checked === 'indeterminate' ? <Icon name="minus" /> : null}
            {checked === true ? <Icon name="check" /> : null}
          </div>
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
    )
  }
)

Checkbox.displayName = 'Checkbox'
