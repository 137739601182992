import * as SwitchPrimitives from '@radix-ui/react-switch'
import { cn } from '@hapstack/common'
import * as React from 'react'

import { Spinner } from './Spinner'

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      'peer inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-accent data-[state=unchecked]:bg-input',
      className
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        'pointer-events-none block size-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0'
      )}
    />
  </SwitchPrimitives.Root>
))

Switch.displayName = 'Switch'

type SwitchSettingProps = {
  title: React.ReactNode
  description?: React.ReactNode
  name: string
  isLoading?: boolean
} & Omit<React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>, 'name'>

const SwitchSetting = ({
  title,
  description,
  name,
  isLoading = false,
  ...props
}: SwitchSettingProps) => {
  return (
    <div className="flex items-center justify-between py-4">
      <div className="flex w-2/3 flex-col">
        <div className="text-lg font-bold">{title}</div>
        {description ? (
          <div className="text-muted-foreground">{description}</div>
        ) : null}
      </div>
      <div className="flex items-center gap-2">
        {isLoading ? <Spinner /> : null}
        <Switch
          name={name}
          {...props}
        />
      </div>
    </div>
  )
}

export { Switch, SwitchSetting }
