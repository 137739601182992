import { cn } from '@hapstack/common'
import type { ComponentProps } from 'react'

export const H1 = ({
  as,
  ...props
}: ComponentProps<'h1'> & { as?: React.ElementType }) => {
  const Component = as || 'h1'

  return (
    <Component
      {...props}
      className={cn(
        'scroll-m-20 text-2.5xl font-bold leading-tight tracking-tighter',
        props.className
      )}
    >
      {props.children}
    </Component>
  )
}

export const H2 = ({
  as,
  ...props
}: ComponentProps<'h2'> & { as?: React.ElementType }) => {
  const Component = as || 'h2'
  return (
    <Component
      {...props}
      className={cn(
        'scroll-m-20 text-xl font-bold leading-tight tracking-tighter',
        props.className
      )}
    >
      {props.children}
    </Component>
  )
}

export const H3 = ({
  as,
  ...props
}: ComponentProps<'h3'> & { as?: React.ElementType }) => {
  const Component = as || 'h3'

  return (
    <Component
      {...props}
      className={cn(
        'scroll-m-20 text-lg font-bold leading-tight tracking-tight',
        props.className
      )}
    >
      {props.children}
    </Component>
  )
}

export const H4 = ({
  as,
  ...props
}: ComponentProps<'h4'> & { as?: React.ElementType }) => {
  const Component = as || 'h4'
  return (
    <Component
      {...props}
      className={cn(
        'scroll-m-20 text-base font-bold leading-tight',
        props.className
      )}
    >
      {props.children}
    </Component>
  )
}
