import { cn } from '@hapstack/common'
import type { ComponentProps } from 'react'

import type { Icon } from './Icon'
import { iconStyles } from './Icon'

export const Spinner = ({
  className,
  size,
  ...props
}: Omit<ComponentProps<typeof Icon>, 'name'>) => (
  <div role="progressbar">
    <svg
      className={cn(
        'h-7 w-7 animate-spin',
        iconStyles({ size }),
        'fill-transparent',
        className
      )}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <circle
        className="stroke-gray-400"
        cx={12}
        cy={12}
        r={10}
        strokeWidth={4}
      />
      <path
        className="fill-accent"
        d="M4 12a8 8 0 0 1 8-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0 1 4 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  </div>
)
