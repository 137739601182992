import { cn } from '@hapstack/common'
import { Slot } from '@radix-ui/react-slot'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'

import type { IconName, IconStyleProps } from './Icon'
import { Icon } from './Icon'
import { Spinner } from './Spinner'

export const buttonStyles = cva(
  'inline-flex items-center justify-center gap-2 rounded-full ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:pointer-events-none disabled:opacity-50 min-w-12 shrink-0 duration-500 font-medium whitespace-nowrap',
  {
    variants: {
      size: {
        xs: 'text-sm px-3 h-8 min-w-0 gap-1',
        sm: 'text-sm px-4 h-9 ',
        md: 'text-md px-4 h-10',
        lg: 'text-base px-5 h-12',
        icon: 'size-10 rounded-full min-w-0',
      },
      variant: {
        default: 'bg-accent hover:bg-accent-dark text-primary-foreground',
        destructive:
          'bg-error-500 text-destructive-foreground hover:bg-error-600 ',
        outline: 'border border-current bg-transparent text-current',
        dark: 'bg-primary text-white ',
        grey: 'bg-primary/5 text-primary border border-border hover:bg-primary/10',
        white: 'bg-white text-primary border border-border hover:bg-secondary',
        ghost: 'hover:bg-secondary',
        link: 'text-primary underline-offset-4 hover:underline h-fit',
        circle:
          'bg-accent rounded-full text-xl font-bold text-white shadow-md transition-all duration-200 hover:scale-105 hover:shadow-lg min-w-0 shadow-sm p-1',
      },
    },
    defaultVariants: {
      size: 'md',
      variant: 'default',
    },
  }
)

export type ButtonStyleProps = VariantProps<typeof buttonStyles>

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonStyleProps & {
    asChild?: boolean
    loading?: boolean
    icon?: IconName
  }

const iconSizeMap: Record<
  NonNullable<ButtonStyleProps['size']>,
  IconStyleProps['size']
> = {
  xs: 'xs',
  sm: 'sm',
  md: 'sm',
  lg: 'md',
  icon: 'lg',
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      children,
      icon,
      loading,
      disabled,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        className={cn(buttonStyles({ variant, size }), className)}
        disabled={loading || !!disabled}
        {...props}
        ref={ref}
      >
        {loading ? (
          <Spinner size={iconSizeMap[size || 'md']} />
        ) : icon ? (
          <Icon
            name={icon}
            size={iconSizeMap[size || 'md']}
          />
        ) : null}
        {children}
      </Comp>
    )
  }
)

Button.displayName = 'Button'
