import { cn } from '@hapstack/common'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import type { ComponentProps, SVGProps } from 'react'
import { forwardRef } from 'react'

import { type IconName, iconNames } from './icons/icon-names'
import iconSpriteHref from './icons/icon-sprite.svg'

export const iconStyles = cva('fill-current shrink-0', {
  variants: {
    size: {
      xxs: 'size-2',
      xs: 'size-3',
      sm: 'size-3.5',
      md: 'size-4',
      lg: 'size-5',
      xl: 'size-6',
      '2xl': 'size-8',
      '3xl': 'size-10',
      '4xl': 'size-12',
      '5xl': 'size-16',
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

export type IconStyleProps = VariantProps<typeof iconStyles>

type IconProps = SVGProps<SVGSVGElement> &
  IconStyleProps & {
    name: IconName
    className?: string
    spriteHref?: string
  }

const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ name, size, className, spriteHref, ...props }, forwardedRef) => {
    return (
      <svg
        {...props}
        className={cn(iconStyles({ size }), className)}
        ref={forwardedRef}
      >
        <use href={`${spriteHref || iconSpriteHref}#${name}`} />
      </svg>
    )
  }
)

Icon.displayName = 'Icon'

const BrandedIcon = ({
  name,
  size,
  className,
  ...props
}: IconProps & ComponentProps<'div'>) => (
  <div
    className={cn(
      'aspect-square w-fit rounded-lg bg-brand-background',
      className
    )}
    {...props}
  >
    <div className="flex h-full w-full items-center justify-center p-2 text-primary">
      <Icon
        name={name}
        size={size}
      />
    </div>
  </div>
)

export const styledIconStyles = cva(
  'flex shrink-0 items-center justify-center rounded-xl',
  {
    variants: {
      size: {
        sm: 'size-6 p-1.5',
        md: 'size-8 p-2',
        lg: 'size-10 p-2.5',
        xl: 'size-12 p-3',
        '2xl': 'size-16 p-3.5',
        '3xl': 'size-20 p-4',
      },
      color: {
        green: 'text-emerald-800 bg-emerald-100',
        purple: 'text-fuchsia-800 bg-fuchsia-100',
        blue: 'text-sky-800 bg-sky-100',
        orange: 'text-orange-800 bg-orange-100',
        red: 'text-red-800 bg-red-100',
        teal: 'text-teal-800 bg-teal-100',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  }
)

type StyledIconStyleProps = VariantProps<typeof styledIconStyles>

type StyledIconProps = StyledIconStyleProps & {
  name?: IconName
  iconClassName?: string
} & ComponentProps<'div'>

const StyledIcon = ({
  name,
  size,
  color,
  className,
  iconClassName,
  children,
  ...props
}: StyledIconProps) => (
  <div
    className={cn(styledIconStyles({ size, color }), className)}
    {...props}
  >
    {children ||
      (name ? (
        <Icon
          name={name}
          className={cn('h-full w-full', iconClassName)}
        />
      ) : null)}
  </div>
)

export { BrandedIcon, Icon, IconName, iconNames, iconSpriteHref, StyledIcon }
