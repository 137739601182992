import { Command as CommandPrimitive, CommandList } from 'cmdk'
import type { ComponentProps, ComponentPropsWithoutRef, Ref } from 'react'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Command, CommandItem } from './Command'
import { Icon } from './Icon'
import { Input } from './Inputs'
import { Spinner } from './Spinner'
import { Tag } from './Tag'

type OptionType = Record<'id' | 'label', string | number>

export type AutocompleteHandle = {
  focus: () => void
  reset: () => void
}

type AutocompleteProps<T extends OptionType> = {
  options: T[]
  onSelectionChange: (selectedOptions: T[]) => void
  onInputChange?: (value: string) => void
  renderOption?: (option: T) => React.ReactNode
  renderSelectedOption?: (option: T) => React.ReactNode
  isLoading?: boolean
  multiSelect?: boolean
} & ComponentPropsWithoutRef<typeof Input>

const Autocomplete = <T extends OptionType>(
  {
    options,
    onInputChange = () => {},
    onSelectionChange = () => {},
    renderOption = (option) => option.label,
    renderSelectedOption = (option) => option.label,
    isLoading,
    multiSelect = false,
    placeholder = 'Search...',
    ...props
  }: AutocompleteProps<T>,
  forwardedRef: Ref<AutocompleteHandle>
) => {
  const [selectedOptions, setSelectedOptions] = useState<T[]>([])
  const [inputValue, setInputValue] = useState('')
  const internalRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(forwardedRef, () => ({
    focus: () => internalRef.current?.focus(),
    reset: () => setSelectedOptions([]),
  }))

  function clearInput() {
    setInputValue('')
  }

  function selectOption(option: T) {
    const newOptions = multiSelect ? [...selectedOptions, option] : [option]
    setSelectedOptions(newOptions)
    onSelectionChange(newOptions)
    clearInput()
  }

  function removeOption(id: T['id']) {
    const newOptions = selectedOptions.filter((o) => o.id !== id)
    setSelectedOptions(newOptions)
    onSelectionChange(newOptions)
  }

  function removeLastOption() {
    const newOptions = selectedOptions.slice(0, selectedOptions.length - 1)
    setSelectedOptions(newOptions)
    onSelectionChange(newOptions)
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (!multiSelect) return
    if (inputValue) return
    if (selectedOptions.length === 0) return
    if (e.key === 'Backspace' || e.key === 'Delete') {
      removeLastOption()
    }
  }

  function handleInputChange(value: string) {
    setInputValue(value)
    onInputChange(value)
  }

  const selectableOptions = useMemo(() => {
    const unselected = options.filter(
      (option) => !selectedOptions.some((s) => s.id === option.id)
    )
    return unselected
  }, [options, selectedOptions])

  const displayOptions = selectableOptions.length > 0

  useEffect(() => {
    internalRef.current?.focus()
  }, [selectedOptions])

  return (
    <Command
      className="overflow-visible"
      shouldFilter={false}
    >
      <div className="group relative flex flex-wrap items-center text-sm">
        <div className="flex w-full flex-col gap-2">
          <div className="flex flex-grow flex-wrap gap-x-1 gap-y-2">
            {selectedOptions.map((option) => {
              return (
                <SelectedOptionTag key={option.id}>
                  {renderSelectedOption(option)}
                  <RemoveOptionButton onClick={() => removeOption(option.id)} />
                </SelectedOptionTag>
              )
            })}
          </div>

          <div className="relative">
            {selectedOptions.length === 0 || multiSelect ? (
              <CommandPrimitive.Input asChild>
                <Input
                  autoFocus
                  icon="search"
                  {...props}
                  ref={internalRef}
                  value={inputValue}
                  onChange={(e) => handleInputChange(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder={placeholder}
                />
              </CommandPrimitive.Input>
            ) : null}
            {isLoading ? (
              <div className="absolute inset-0 flex items-center justify-center">
                <LoadingSpinner />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <CommandList className="relative mt-1">
        {displayOptions ? (
          <div className="absolute top-0 z-10 w-full space-y-1 rounded-md border border-border bg-popover p-2 shadow-sm">
            {selectableOptions.map((option) => {
              return (
                <CommandItem
                  key={option.id}
                  value={String(option.id)}
                  onMouseDown={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  onSelect={() => selectOption(option)}
                  className="transition-colors duration-200"
                >
                  {renderOption(option)}
                </CommandItem>
              )
            })}
          </div>
        ) : null}
      </CommandList>
    </Command>
  )
}

const FRAutocomplete = forwardRef(Autocomplete) as <T extends OptionType>(
  p: AutocompleteProps<T> & { ref?: Ref<AutocompleteHandle> }
) => React.ReactElement

export { FRAutocomplete as Autocomplete }

const SelectedOptionTag = ({ children }: { children: React.ReactNode }) => {
  return (
    <Tag
      size="lg"
      color="grey"
      className="flex items-center gap-1 py-1"
    >
      {children}
    </Tag>
  )
}

const RemoveOptionButton = ({ onClick }: ComponentProps<'button'>) => {
  return (
    <button
      className="rounded-full outline-none ring-offset-background transition-transform hover:scale-110 focus:ring-2 focus:ring-ring focus:ring-offset-2"
      onMouseDown={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onClick={onClick}
    >
      <Icon
        name="x"
        size="xs"
        className="opacity-50"
      />
    </button>
  )
}

const LoadingSpinner = () => (
  <div className="absolute right-2.5 flex h-full items-center justify-center">
    <Spinner />
  </div>
)
