import type { ComponentProps } from 'react'
import { z } from 'zod'

const urlSchema = z.string().url()

type XAxisTickProps = Pick<
  ComponentProps<'svg'>,
  | 'dx'
  | 'dy'
  | 'fill'
  | 'pointerEvents'
  | 'stroke'
  | 'textAnchor'
  | 'width'
  | 'x'
  | 'y'
> & {
  formattedValue: any
  tickSize?: number
}

export const CustomTickImage = ({
  x,
  y,
  formattedValue,
  tickSize = 24,
  ...props
}: XAxisTickProps) => {
  const xCoord = x ? +x : 0
  const yCoord = y ? +y : 0

  const href = urlSchema.safeParse(formattedValue)

  if (!href.success) return null

  return (
    <svg
      x={xCoord - 12}
      y={yCoord - 4}
      {...props}
      viewBox={`0 0 ${tickSize} ${tickSize}`}
      width={tickSize}
      height={tickSize}
    >
      <defs>
        <rect
          id="rect"
          x="0%"
          y="0%"
          width="100%"
          height="100%"
          rx="7"
          fill="transparent"
        />
        <clipPath id="clip">
          <use xlinkHref="#rect" />
        </clipPath>
      </defs>
      <use
        xlinkHref="#rect"
        strokeWidth="2"
        stroke="transparent"
      />
      <image
        href={href.data}
        height="100%"
        width="100%"
        clipPath='url("#clip")'
      />
    </svg>
  )
}
