import { cn } from '@hapstack/common'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import type { ComponentPropsWithoutRef } from 'react'
import { forwardRef } from 'react'

import { Icon, type IconName } from './Icon'

export const tagStyles = cva(
  'inline-flex w-fit items-center justify-center select-none font-medium transition-colors duration-300 gap-1.5',
  {
    variants: {
      color: {
        brand: 'bg-pink-100 text-pink-800',
        green: 'text-emerald-800 bg-emerald-100',
        red: 'bg-rose-100 text-rose-800 ',
        yellow: 'bg-warning-200/40 text-warning-700/80',
        blue: 'bg-sky-100 text-sky-800 ',
        orange: 'bg-orange-100 text-orange-900',
        grey: 'bg-gray-100 text-gray-800',
        white: 'bg-white text-secondary-foreground border-border border',
        outline: 'bg-transparent text-current border border-current border',
        teal: 'bg-teal-100 text-teal-800',
        purple: 'bg-fuchsia-100 text-fuchsia-800',
        primary: 'bg-primary text-white',
      },
      rounded: {
        xs: 'rounded-xs',
        sm: 'rounded-sm',
        md: 'rounded-md',
        lg: 'rounded-lg',
        full: 'rounded-full',
      },
      size: {
        sm: 'h-5 text-xxs py-px px-1.5',
        md: 'h-auto text-xs py-0.5 px-2',
        lg: 'text-sm h-auto py-0.5 px-2.5',
        xl: 'text-md h-auto py-1 px-3',
      },
    },
    defaultVariants: {
      color: 'grey',
      rounded: 'md',
      size: 'md',
    },
  }
)

export type TagStyleProps = VariantProps<typeof tagStyles>
type TagProps = TagStyleProps &
  React.ComponentProps<'div'> & {
    icon?: IconName
  }

export const Tag = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'> & TagProps
>(({ color, rounded, className, size, icon, ...props }, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className={cn(tagStyles({ color, rounded, size }), className)}
    >
      {icon ? (
        <Icon
          name={icon}
          size="xs"
        />
      ) : null}
      {props.children}
    </div>
  )
})

Tag.displayName = 'Tag'
