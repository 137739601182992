import { cn } from '@hapstack/common'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import * as React from 'react'

import { Label } from './Label'

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('grid gap-2', className)}
      {...props}
      ref={ref}
    />
  )
})
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        'aspect-square size-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
        <div className="size-2.5 rounded-full bg-current" />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  )
})
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

const RadioGroupItemWithLabel = ({
  item,
  className,
  ...props
}: React.ComponentProps<'div'> & {
  item: { value: string; label: string }
}) => {
  return (
    <div
      className={cn(
        "flex h-12 w-44 items-center justify-center space-x-2 rounded-md border px-2 transition-all duration-300 [&:has(>[data-state='checked'])]:border-border [&:has(>[data-state='checked'])]:bg-secondary/80",
        className
      )}
      {...props}
    >
      <RadioGroupItem
        id={item.value}
        value={item.value}
      />
      <Label
        htmlFor={item.value}
        className="flex w-full cursor-pointer items-center self-stretch text-base"
      >
        {item.label}
      </Label>
    </div>
  )
}

export { RadioGroup, RadioGroupItem, RadioGroupItemWithLabel }
